<template>
  <div class="home-page-container">
    <AppHeader :brands="brands"></AppHeader>
    <div class="main-container">
      <el-row>
        <!-- 侧边栏 -->
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="hidden-sm-and-down">
          <el-menu :default-active="defaultMenuItemIndex" class="el-menu-vertical-demo" @select="handleSelect">
            <el-popover
                placement="right-start"
                width="850"
                trigger="hover">
              <div class="popover-content">
                <el-row>
                  <el-col :span="6" v-for="brand in brands">
                    <el-menu-item :index="brand.uri">{{ brand.enName }}</el-menu-item>
                  </el-col>
                </el-row>
              </div>
              <el-menu-item index="all" slot="reference">
                ALL
                <i class="menu-icon el-icon-notebook-2"></i>
              </el-menu-item>
            </el-popover>
            <el-menu-item v-for="brand in brands" :index="brand.uri">
              {{ brand.enName }}
            </el-menu-item>
          </el-menu>
        </el-col>

        <!-- 主要内容区域 -->
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
          <div class="images-container">
            <!-- 相册列表 -->
            <el-row :gutter="15"> <!--style="flex-wrap: wrap;" 保持样式不乱-->
              <el-col :xs="8" :sm="6" :md="4" :lg="4" :xl="4" v-for="pro in products" :key="pro.id">
                <el-card class="box-card" :body-style="{ padding: '0px' }" shadow="hover">
                  <div class="image-container">
                    <a :href="'/product/' + pro.id" target="_blank">
                      <el-image class="image" fit="cover" :src="getThumbImageUrl(pro.image)">
                        <div slot="placeholder" class="image-slot">
                          <el-image :src="loading"></el-image>
                        </div>
                      </el-image>
                      <div class="image-label">
                        <img v-if="pro.grade === 2" :src="require('@/assets/images/aaa.png')" alt="label"
                             class="label-image"/>
                        <img v-if="pro.grade === 3" :src="require('@/assets/images/swiss.png')" alt="label"
                             class="label-image"/>
                      </div>
                    </a>
                  </div>
                  <div class="card-text">
                    <p class="card-title">{{ pro.enName }}</p>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="page">
            <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="listProduct"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue'; // 更新导入路径
import SiteFooter from './SiteFooter.vue'; // 更新导入路径
import loading from '@/assets/images/loading.gif';
import {
  listBrand,
  getProductsByBrand,
} from '@/api/product'; // 导入获取产品数据的方法


export default {
  name: 'HomePage',
  components: {
    AppHeader,
    SiteFooter,
  },

  data() {
    return {
      loading: loading,
      defaultMenuItemIndex: '0',
      products: [],
      brands: [],
      // 查询参数
      total: 0,
      brand: null,
      queryParams: {
        pageNum: 1,
        pageSize: 60,
        brandId: null,
        enName: null,
      }
    };
  },
  methods: {
    getThumbImageUrl(imageId) {
      if (imageId) {
        if (imageId.startsWith('http')) {
          return imageId;
        } else {
          return "https://s-cf-tw.shopeesz.com/file/" + imageId + "_tn";
        }
      }
      return '';
    },
    handleSelect(key, keyPath) {
      const currentRoute = this.$route.path;
      const targetRoute = `/brand/${key}`;
      // 检查是否尝试进行重复导航
      if (currentRoute !== targetRoute) {
        // 使用编程式导航来改变路由
        this.$router.push(targetRoute);
      }
    },
    getBrandList() {
      listBrand().then(response => {
        this.brands = response.data;
      });
    },
    listProduct() {
      getProductsByBrand(this.queryParams, this.brand).then(response => {
        this.products = response.rows;
        this.total = response.total;
      });
    },
    // viewDetail(productId) {
    //   this.$router.push("/product/" + productId);
    // },
  },
  watch: {
    '$route'(to) {
      // 重置查询参数
      this.brand = null;
      this.queryParams.enName = null;

      // 根据路由名称更新查询参数
      switch (to.name) {
        case 'Brand':
          // 当路由为Brand时，使用路由参数更新BrandId
          this.brand = to.params.brand;
          break;
        case 'Search':
          // 当路由为Search时，使用路由参数更新name
          this.queryParams.enName = to.params.enName;
          break;
      }
      this.listProduct();
    }
  },
  created() {
    // 基于不同的路由设置不同的查询参数
    switch (this.$route.name) {
      case 'HomePage':
        // 如果是主页，可能不需要设置特定的查询参数
        break;
      case 'Brand':
        // 如果是品牌页面，设置品牌ID
        this.brand = this.$route.params.brand;
        break;
      case 'Search':
        this.queryParams.enName = this.$route.params.enName;
        break;
      case 'Detail':
        // 详情页可能不需要设置这里的查询参数
        break;
      default:
        // 默认情况下，不设置特定的查询参数
    }
    // 组件创建时获取数据
    this.getBrandList();
    this.listProduct();
  },
};
</script>

<style scoped>
.main-container {
  justify-content: center;
  align-items: flex-start;
  padding: 30px 15px;
  max-width: 1350px;
  margin: 0 auto;
}

/* 合并后的侧边栏el-menu样式 */
.el-menu-vertical-demo {
  background-color: #f5f5f5; /* 淡背景色 */
  margin: 0 15px; /* 左右各添加10px的外边距 */
  border: 1px solid #dee2ee; /* 淡边框色 */
}

/* 设置每个菜单项之间的边框线 */
.el-menu-vertical-demo .el-menu-item {
  border-bottom: 1px solid #dee2ee; /* 很淡的边框线 */
}

/* 移除最后一个菜单项的底部边框，以避免重复边框 */
.el-menu-vertical-demo .el-menu-item:last-child {
  border-bottom: none;
}

/* 可选：为悬停状态的菜单项添加背景色 */
.el-menu-vertical-demo .el-menu-item:hover {
  background-color: #ebeef5; /* 悬停时的背景色 */
}

/* 可选：为激活状态的菜单项添加背景色 */
.el-menu-vertical-demo .is-active {
  background-color: #ebeef5; /* 激活状态的背景色 */
}

.el-menu-item {
  display: flex; /* 使用flex布局 */
  align-items: center; /* 垂直居中 */
  padding-right: 10px; /* 给右侧留出空间 */
}

.menu-icon {
  font-size: 18px; /* 图标大小 */
  margin-left: auto; /* 将图标推向右侧 */
  margin-right: 10px;
}

.popover-content .el-col {
  display: flex;
  justify-content: center;
  border-bottom: 1px dashed #dcdfe6; /* 设置下边框为虚线，颜色为淡灰色 */
}

.page {
  display: flex; /* 使用flex布局 */
  justify-content: center; /* 水平居中 */
  margin-top: 20px; /* 根据需要添加上外边距 */
}

.box-card {
  margin-bottom: 15px;
  width: 100%; /* 使卡片宽度与列宽度一致 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 添加过渡效果 */
  transform: scale(1); /* 默认缩放比例 */
}

.box-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* 增加阴影大小和透明度 */
  transform: scale(1.05); /* 轻微放大 */
}

/* 图片样式 */
.image-container {
  position: relative; /* 为了绝对定位图片 */
  width: 100%; /* 容器宽度填满其父容器 */
  height: 0; /* 高度设置为0 */
  padding-bottom: 100%; /* padding-bottom 等于宽度的百分比，形成正方形 */
  overflow: hidden; /* 隐藏溢出的部分 */
}

.image {
  position: absolute; /* 绝对定位，相对于.image-container */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-text {
  padding: 3px 10px;
}

.card-title {
  font-size: 14px;
  color: #333;
  margin: 0 0 5px 0; /* 底部留出空隙 */
  overflow: hidden; /* 隐藏溢出的内容 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 溢出的文本显示省略号 */
}

.image-label {
  position: absolute;
  right: 0px; /* 调整标签的位置 */
  bottom: -5px; /* 调整标签的位置 */
  z-index: 10;
}

.label-image {
  width: 60px; /* 标签图片的宽度 */
  height: auto; /* 保持比例 */
}

</style>
